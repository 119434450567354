import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import { web3 } from '../web3';
import {
  saveloginData, setDashboardStats, setTransactionData, toggleClaimLoader, setUsersData, toggleEditUser, setMintHistory,
  setBurnHistory, toggleReleaseLoader, setReleased, setDirectBuy, editDirectBuy, isloginDisabled
} from '../actions/Auth';

/*========== NONCE FUNCTIONS =============*/

function* getNonce({ payload, history }) {
  const { error, response } = yield call(getCall, `/users/getNonce/${payload}`);
  if (error) {
    yield put({ type: "IS_LOGIN_DISABLED" });
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    var nonceObj = response['data']['body']['nonceObject'];
    // SIGING THE NONCE
    try {
      const signature = yield web3.eth.personal.sign(web3.utils.utf8ToHex(`Login to Horizon - Nonce: ,${nonceObj["nonce"]}`), payload)
      const data = {
        signature,
        publicAddress: payload,
      }
      const metaMaskResponse = yield call(postCall, { path: `/users/loginWithMetaMask`, payload: data });
      if (error) {
        yield put({ type: "IS_LOGIN_DISABLED" });
        EventBus.publish("error", error['response']['data']['message']);
      }
      else if (metaMaskResponse) {
        let { token } = metaMaskResponse['response']['data']['body']
        const decoded = jwt_decode(token, { header: false });

        if (decoded["role"] !== "admin") {
          EventBus.publish("error", "Can't login through User account");
          yield put({ type: "IS_LOGIN_DISABLED" });
          return;
        }
        yield put(saveloginData(token));
        yield put({ type: "IS_LOGIN_DISABLED" });

        EventBus.publish("success", metaMaskResponse['response']['data']['message'])
        setTimeout(() => history.push('/home'), 1000);
      }
    } catch (e) { yield put({ type: "IS_LOGIN_DISABLED" }); }
  }
};

/*========== DASHBOARD FUNCTIONS =============*/

function* getDashboardStats() {
  const { error, response } = yield call(getCall, '/blockchain/dashboard');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setDashboardStats(response['data']['body']));
};

/*========== TRANSACTIONS DATA FUNCTIONS =============*/

function* getTransactionData() {
  const { error, response } = yield call(getCall, '/blockchain/allTransactions');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setTransactionData(response['data']['body']));
};

/*========== REFUND BNB =============*/

function* refundUSDT() {
  const { error, response } = yield call(getCall, '/blockchain/refundUSDT');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put(toggleClaimLoader());
};

/*========== RELEASE TOKENS =============*/

function* releaseTokens() {
  const { error, response } = yield call(getCall, '/blockchain/transferTokens');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(setReleased());
    EventBus.publish("success", response['data']['message']);
  }
  yield put(toggleReleaseLoader());
};

/*========== USERS DETAILS FUNCTIONS =============*/

function* getUsersData() {
  const { error, response } = yield call(getCall, '/users/getUsers');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setUsersData(response['data']['body']));
};

function* editUser({ payload }) {
  const { error, response } = yield call(putCall, { path: `/users/updateUser/${payload['id']}`, payload: payload['data'] });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put({ type: 'GET_USERS_DATA' });
    yield put({ type: 'TOGGLE_EDIT_USER', payload: {} });
  }
};

function* deleteUser({ payload }) {
  const { error, response } = yield call(getCall, `/users/deleteUser/${payload}`);
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put({ type: 'GET_USERS_DATA' });
    yield put({ type: 'TOGGLE_DELETE_USER' });
    EventBus.publish("success", response['data']['message']);
  } 
};

/*========== MINIT TOKENS =============*/

function* mintTokens({ payload }) {
  const { error, response } = yield call(postCall, { path: `/blockchain/mint`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put({ type: 'TOGGLE_MINTING_MODAL' });
    EventBus.publish("success", response['data']['message']);
  }
  yield put({ type: 'MINT_HISTORY' });
};

function* mintHistory() {
  const { error, response } = yield call(getCall, '/blockchain/mint');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setMintHistory(response['data']['body']));
};

/*========== BURN TOKENS =============*/

function* burnTokens({ payload }) {
  const { error, response } = yield call(postCall, { path: `/blockchain/burn`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put({ type: 'TOGGLE_BURN_MODAL' });
    EventBus.publish("success", response['data']['message']);
  }
  yield put({ type: 'BURN_HISTORY' });
};

function* burnHistory() {
  const { error, response } = yield call(getCall, '/blockchain/burn');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setBurnHistory(response['data']['body']));
};



function* actionWatcher() {
  yield takeEvery('GET_NONCE', getNonce);
  yield takeEvery('EDIT_USER', editUser);
  yield takeEvery('REFUND_BNB', refundUSDT);
  yield takeEvery('BURN_TOKENS', burnTokens);
  yield takeEvery('MINT_TOKENS', mintTokens);
  yield takeEvery('BURN_HISTORY', burnHistory);
  yield takeEvery('MINT_HISTORY', mintHistory);
  yield takeEvery('GET_USERS_DATA', getUsersData);
  yield takeEvery('DELETE_USER', deleteUser);
  yield takeEvery('RELEASE_TOKENS', releaseTokens);
  yield takeEvery('GET_DASHBOARD_STATS', getDashboardStats);
  yield takeEvery('GET_TRANSACTION_DATA', getTransactionData);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}
