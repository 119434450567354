import Dashboard from "./views/Dashboard/index.js";
import TokenDetails from "./views/TokenDetails/index.js";
import UsersDetails from "./views/UsersDetails/index.js";
import Settings from "./views/Settings/index.js";


var routes = [
  {
    layout: "/home",
    path: "/",
    component: Dashboard,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    icon: "tim-icons icon-chart-pie-36",
  },
  // {
  //   layout: "/home",
  //   path: "/tokenDetails",
  //   name: "Token Details",
  //   component: TokenDetails,
  //   icon: "tim-icons icon-money-coins",
  // },
  {
    layout: "/home",
    path: "/usersDetails",
    name: "Users Details",
    component: UsersDetails,
    icon: "tim-icons icon-badge",
  },
  {
    layout: "/home",
    path: "/settings",
    name: "Admin Settings",
    component: Settings,
    icon: "tim-icons icon-settings-gear-63",
  },
];

export default routes;